<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-card-text class="text-center py-5"> <h1>Welcome To Creative IT</h1> </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
